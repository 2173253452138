import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppReg } from "../../assets/Svg";
import {
  deleteBillByClient,
  deleteBillZkTraders,
  getClientById,
} from "../../apiservices";
import { toast } from "react-toastify";
import moment from "moment";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  addSales,
  view,
  rowData,
  getBillData,
  clientData,
}) {
  if (rowData) {
    rowData.forEach((element) => {
      if (!element?.id) {
        let filtered = clientData.filter(
          (item) => item._id == element?.clientId
        );
        element["id"] = element?._id;
        element["date"] = moment(element.date).format("DD-MM-YYYY");
      }
    });
  }
  const columns = [
    { field: "date", headerName: "Date" },
    { field: "billNumber", headerName: "Invoice#" },
    { field: "client", headerName: "Client", width: 250 },
    {
      field: "seller",
      headerName: "Seller",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Total Amount",
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => view(params.row)}>
            view Bills
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Btn
              variant="contained"
              color="primary"
              size="small"
              onClick={() => addSales(params.row)}>
              <AppReg />
            </Btn>
          </>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <strong style={{ color: "red" }}>
              <DeleteIcon onClick={() => deleteRow(params.row)} />

              {/* You can add more buttons or customize based on your requirements */}
            </strong>
          </>
        );
      },
    },
  ];
  const rows = rowData !== undefined ? rowData : [];
  const deleteRow = async (params) => {
    let filtered = clientData.filter(
      (item) => item.clientName === params.client
    );
    let clientObj = {
      _id: filtered[0]?._id,
      srNum: params._id,
    };
    let obj = {
      _id: params._id,
    };
    let res = await deleteBillZkTraders(obj);
    if (res) {
      let obj = {
        _id: filtered && filtered[0]?._id,
      };
      await deleteBillByClient(clientObj);
      await getClientById(obj);
      toast.error("Sale deleted successfully");
      getBillData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
