import React from "react";
import Logo from "../../assets/erorex-log.png";
import {
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "1px",
}));
function BillPreview({ data, tableArr, clientBalance }) {
  return (
    <Container>
      <Stack
        sx={{ mb: 2, mt: 1 }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        spacing={3}>
        <img src={Logo} alt="logo" width={"250"} height={"70"} />

        <Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
            ZR Traders
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Shop No 8, Ali Market Sabzi Mandi
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Raja Bazar Rawalpindi. Ph:051-5557038
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Cell: 03205337777 Email: info@maazpro.com
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Invoice
        </Typography>
      </Stack>
      <Grid
        container
        style={{ marginBottom: "10px", marginTop: 8 }}
        spacing={1}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Seller</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>{data?.seller}</Typography>
            {/* <Typography sx={{ padding: 0, margin: 0, mx: 2 }}>
              {userData && userData?.address}
            </Typography>

            <Typography sx={{ padding: 0, margin: 0, mx: 2 }}>
              {userData && userData?.phone}
            </Typography> */}
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Client</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {(data && data.name) || data.client}
            </Typography>
            {/* <Typography sx={{ mx: 2 }}>
              {" "}
              {(data && data?.selectedClient?.city) || data.city}
            </Typography>
            <Typography sx={{ mx: 2 }}> {data && data.clientNo}</Typography> */}
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              Invoice #
            </Typography>
          </Stack>

          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {(data && data.billNo) || data.billNumber}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>Date</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {data && data.date}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Table
        sx={{
          minWidth: 400,
          "& .MuiTableCell-root": {
            border: "1px solid #616161",
          },
        }}
        aria-label="simple table">
        <TableHead
          sx={{
            background: "#E0E0E0",
          }}>
          <StyledTableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left" sx={{ width: "120px" }}>
              Item
            </TableCell>

            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Amount</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableArr &&
            tableArr.map((item, index) => (
              <StyledTableRow key={item._id} sx={{ height: "50px" }}>
                <TableCell className="bill-heading">{index + 1}</TableCell>
                <TableCell className="bill-heading" sx={{ width: "350px" }}>
                  {item.name || item.modelName}
                </TableCell>

                <TableCell className="bill-heading">
                  {item?.quantity || item.qty}
                </TableCell>
                <TableCell className="bill-heading">{item?.price}</TableCell>
                <TableCell className="bill-heading">
                  {parseInt(item?.quantity || item.qty) * parseInt(item?.price)}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>

      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        sx={{ marginTop: "40px" }}
        spacing={2}>
        <Stack alignItems={"center"}>
          <Typography>Sub Total </Typography>
          <Typography>Rs {data && data.amount}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>Old Balance </Typography>
          <Typography>Rs {data && data.prev}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>Total Amount</Typography>
          <Typography>Rs {data && data.total}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>Paid Amount</Typography>
          <Typography>Rs {data && data.paid}</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <Typography>New Balance </Typography>
          <Typography>Rs {clientBalance}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

export default BillPreview;
