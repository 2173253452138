import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack, styled } from "@mui/material";
import profileImg from "../../assets/maaz-Logo-1.png";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DialogComponent from "../../reuseableComp";
// import DeleteDialog from "../reusableComponents/DeleteDialog";
import EmpDetails from "./EmpDetails";

const RootStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
});

const ItemBox = styled("div")({
  backgroundColor: "white",
  minHeight: "200px",
  boxShadow: "0px 1px 2px rgb(161, 161, 161)",
  padding: "10px",
  borderRadius: "5px",
  margin: "5px 10px",
});
const IconBox = styled("div")({
  color: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px",
  background: "#fff",
  boxShadow: "0px 4px 16px rgb(0 0 0 / 16%)",
  borderRadius: "4px",
  cursor: "pointer !important",
  margin: "0px 10px",
});
const GreyBox = styled(Stack)({
  fontWeight: "normal",
  background: "#f3f3f3",
  borderBottom: "1px solid #999999",
  borderRadius: "6px",
  padding: "12px 70px",
  width: "90%",
});

function EmployeesList({ employees, onSuccess }) {
  const [state, setState] = useState({
    deleteModal: false,
    detailModal: false,
    employeeDetail: "",
    selectedEmp: "",
  });

  const toggle = (name) => {
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleDelete = (id) => {
    setState((prev) => ({ ...prev, selectedEmp: id, deleteModal: true }));
  };

  const handleDetail = (data) => {
    console.log(data);
    setState((prev) => ({ ...prev, employeeDetail: data, detailModal: true }));
  };

  return (
    <Box sx={{ mb: "25px" }}>
      <RootStyle>
        {employees &&
          employees.map((employee, index) => {
            return (
              <Stack marginTop="20px">
                <ItemBox>
                  <img
                    width="150px"
                    height="170px"
                    src={employee.base_url ? employee.base_url : profileImg}
                    onClick={() => handleDetail(employee)}
                    alt=""
                  />
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {employee.username}
                  </Typography>
                </ItemBox>
                <IconBox onClick={() => handleDelete(employee._id)}>
                  <DeleteTwoToneIcon />
                </IconBox>
              </Stack>
            );
          })}
      </RootStyle>

      {/* <DeleteDialog
        title="Delete Employee"
        open={state.deleteModal}
        onClose={() => toggle("deleteModal")}
        // onClickYes={deleteEmployee}
        onClickCancel={() => toggle("deleteModal")}
      /> */}
      <DialogComponent
        title="Employee"
        open={state.detailModal}
        onClose={() => toggle("detailModal")}
        dialogContent={
          <EmpDetails
            user={state.employeeDetail}
            onSuccess={onSuccess}
            onClose={() => toggle("detailModal")}
          />
        }
      />
    </Box>
  );
}

export default EmployeesList;
