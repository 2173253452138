import React from "react";
import Logo from "../../assets/erorex-log.png";

import {
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function StockPreview({ data, tableArr }) {
  return (
    <Container>
      <Stack sx={{ mb: 2 }}>
        <img src={Logo} alt="logo" width={"200"} height={"50"} />
      </Stack>
      <Grid container style={{ marginBottom: "10px" }} spacing={1}>
        <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              {" "}
              Buyer Name
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>{data.buyer}</Typography>
          </Stack>
        </Grid>

        <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              {" "}
              Provider Name
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {data && data.providersName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#ff0000",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              Company Name
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {data && data?.companyName}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Table sx={{ minWidth: 400, mt: 3 }} aria-label="simple table">
        <TableHead
          sx={{
            background: "#A5D6A7",
          }}>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left">Item</TableCell>

            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArr.map((item, index) => (
            <TableRow key={item._id}>
              <TableCell className="bill-heading">{index + 1}</TableCell>
              <TableCell className="bill-heading">
                {item.name || item.modelName}
              </TableCell>

              <TableCell className="bill-heading">
                {item?.quantity || item.qty}
              </TableCell>
              <TableCell className="bill-heading">{item?.price}</TableCell>
              <TableCell className="bill-heading">
                {parseInt(item?.quantity || item.qty) * parseInt(item?.price)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        sx={{ marginTop: "40px" }}
        spacing={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Total Stock: </Typography>
          <Typography> {data && data.totalStock}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Total Amount: </Typography>
          <Typography> {data && data.totalPrice}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

export default StockPreview;
