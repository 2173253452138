import React, { useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import profileImg from "../../assets/maaz-Logo-1.png";
import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import Address from "./Address";
import DialogComponent from "../../reuseableComp";
import UpdatePassword from "./UpdatePassword";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const Banner = styled(Stack)({
  background: "#52b335",
  borderRadius: "4px",
  textAlign: "center",
  color: "white",
  padding: "5px 5px",
  margin: "10px 0px",
  height: "100px",
});
const imgStyle = {
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  width: "120px",
  height: "120px",
};
const profileImgStyle = {
  maxWidth: "80px",
  maxHeight: "80px",
  // marginTop: "40px",
  border: "5px solid white",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 0px 4px #bebebe",
  // zIndex: 1,
};
const ProfileSection = styled(Stack)({
  marginTop: "-40px",
  marginLeft: "20px",
});
const ProfileIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  boxShadow: "0px 4px 16px rgb(0 0 0 / 16%)",
  borderRadius: "4px",
  cursor: "pointer",
  padding: "5px",
});
const ProfileData = styled(Stack)({
  borderRadius: "6px",
  background: "#ffffff",
  padding: 40,
  marginTop: 60,
});
const GreyBox = styled(Stack)({
  fontWeight: "normal",
  background: "#f3f3f3",
  borderBottom: "1px solid #999999",
  borderRadius: "6px",
  padding: "12px 70px",
  // width: "100%",
});
function CompanyInfo() {
  const theme = useTheme();

  let userData = JSON.parse(localStorage.getItem("user"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [state, setState] = useState({
    email: userData.email,
    password: "",
    tos: "",
    AddEdit: false,
    passEdit: false,
  });
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const edit = (name) => {
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  return (
    <div>
      <Banner
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}>
        <img style={profileImgStyle} src={profileImg} alt="" />
        <Stack>
          <Typography variant={fullScreen ? "p" : "h5"}>
            Welcome to your company profile
          </Typography>
          <Typography variant={fullScreen ? "p" : "h4"}>
            {userData.company_name || ""}
          </Typography>
        </Stack>
        <Stack></Stack>
        <Stack></Stack>
      </Banner>
      <ProfileSection justifyContent={"start"} alignItems={"start"}>
        {/* <Box sx={{ zIndex: 5 }}> */}
        {/* <Stack direction="row" justifyContent={"space-around"}>
            <ProfileIcon sx={{ color: "#4EAF51" }}>
              <CameraAltIcon />
            </ProfileIcon>
            <ProfileIcon sx={{ color: "red" }}>
              <DeleteTwoToneIcon />
            </ProfileIcon>
          </Stack> */}
        {/* </Box> */}
      </ProfileSection>

      <ProfileData>
        <Grid
          container
          spacing={2}
          sx={{
            boxShadow: "0px 0px 8px #bebebe",
            borderRadius: "6px",
            padding: 2,
          }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              CEO:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <GreyBox alignItems="center" justifyContent="center">
              {userData.name}
            </GreyBox>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              Address:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <GreyBox alignItems="center" justifyContent="center">
              {userData.address}
            </GreyBox>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              Company Code:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <GreyBox alignItems="center" justifyContent="center">
              {userData.company_code}
            </GreyBox>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              Email:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <GreyBox
              alignItems="center"
              justifyContent="center"
              sx={{ padding: 0 }}>
              <TextField
                fullWidth
                disabled={!state.AddEdit}
                id="outlined-basic"
                value={state.email}
                name="email"
                onChange={handleChange}
              />
            </GreyBox>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              Terms and Conditions:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} align="right">
            <GreyBox
              alignItems="center"
              justifyContent="center"
              sx={{ padding: 0 }}>
              <TextField
                disabled={!state.AddEdit}
                fullWidth
                id="outlined-basic"
                value={state.tos}
                name="email"
                onChange={handleChange}
              />
            </GreyBox>
            {/* <Stack direction={"row-reverse"} spacing={2} sx={{ mt: "5px" }}>
              <Button
                variant="contained"
                onClick={() => edit("AddEdit")}
                sx={{
                  textTransform: "capitalize",
                  background: "#52b335",
                  "&:hover": {
                    background: "#52b335",
                  },
                }}
                size="small">
                <BorderColorIcon
                  style={{ height: "17px", width: "17px", marginRight: "9px" }}
                />
                Address & Tos
              </Button>
            </Stack> */}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h6" sx={{ color: "#4EAF51" }}>
              Current Password:
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} align="right">
            <GreyBox
              alignItems="center"
              justifyContent="center"
              sx={{ padding: 0 }}>
              <TextField
                fullWidth
                disabled={!state.passEdit}
                id="outlined-basic"
                type="password"
                value={userData?.password}
              />
            </GreyBox>
            <Stack direction={"row-reverse"} spacing={2} sx={{ mt: "5px" }}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  mt: 5,
                  background: "#52b335",
                  "&:hover": {
                    background: "#52b335",
                  },
                }}
                onClick={() => edit("passEdit")}>
                <BorderColorIcon
                  style={{ height: "17px", width: "17px", marginRight: "9px" }}
                />{" "}
                Edit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </ProfileData>
      <DialogComponent
        title="Address"
        open={state.AddEdit}
        onClose={() => edit("AddEdit")}
        dialogContent={
          <Address
            user={userData}
            onClose={() => edit("AddEdit")}
            // onSuccess={getUser}
          />
        }
      />
      <DialogComponent
        title="Password"
        open={state.passEdit}
        onClose={() => edit("passEdit")}
        dialogContent={
          <UpdatePassword id={userData} onClose={() => edit("passEdit")} />
        }
      />
    </div>
  );
}

export default CompanyInfo;
